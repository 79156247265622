import React from 'react'
import {graphql} from "gatsby";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {MDXProvider} from "@mdx-js/react";
import {MDXRenderer} from "gatsby-plugin-mdx";
import Card from "@material-ui/core/Card";
import components from "../components/mdx_components";
import SEO from "../components/seo";
import GatsbyImage from "gatsby-image";
import VideoCard from "../components/VideoCard";
import EventsCard from "../components/EventsCard";
import Carousel from "react-material-ui-carousel";

const Page = ({data}) => {
  const events = data.events.nodes.filter(x => new Date(x.frontmatter.date) > new Date())

  return (
    <React.Fragment>
      <SEO title="Korbflechten"/>
      <Container maxWidth="md">
        <Box my={1}>
          <Card>
            <GatsbyImage fluid={data.file.childImageSharp.fluid}/>
            <Box m={1}>
              <MDXProvider components={components}><MDXRenderer>{data.mdx.body}</MDXRenderer></MDXProvider>
            </Box>
          </Card>
        </Box>
        {events.length > 0 ? (
          <Box my={1}>
            <EventsCard events={events}/>
          </Box>
        ) : null}
        <Box my={1}>
          <Card>
            <Box m={1}>
              <Carousel>
                {data.images.edges.map(image => <GatsbyImage key={image.node.relativePath} fluid={image.node.childImageSharp.fluid}/>)}
              </Carousel>
            </Box>
          </Card>
        </Box>
        <Box my={1}>
          <VideoCard videoId={data.video.frontmatter.videoId} caption={data.video.frontmatter.caption}/>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
    query {
        video: mdx(frontmatter: { title: { eq: "vidKorbflechten" } }) {
            frontmatter {
                videoId
                caption
            }
        }
        mdx(frontmatter: { title: { eq: "Korbflechten" } }) {
            body
        }
        file(relativePath: { eq: "images/korbflechten.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 882, maxHeight: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        events: allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {slug: {regex: "/events/"}, frontmatter: {topic: {eq: "korbflechten"}}}) {
            nodes {
                id
                body
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    time: date(formatString: "HH:mm")
                    title
                    location
                    price
                }
            }
        }
        images:   allFile(filter: {relativePath: {regex: "images//korbflechten//"}}) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 882, maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

export default Page
